<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Favorite Comments" />

    <FavoriteCommentsList :striped="true" :hover="true" />
  </div>
</template>

<script>
import PageHeader from 'components/PageHeader';
import FavoriteCommentsList from 'components/favorite-comments/FavoriteCommentsList';

export default {
  name: 'FavoriteCommentsIndex',
  components: {
    FavoriteCommentsList,
    PageHeader,
  },
};
</script>

<style scoped lang="scss"></style>
