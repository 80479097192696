import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

const shortDateTimeFormat = 'MM/DD/YYYY h:mm A';
const fullDateFormat = 'MMMM D, YYYY';
const fullDateTimeFormat = 'MMMM D, YYYY h:mm A';

dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(relativeTime);
const hasTimeDate = {
  props: {
    autoUpdateDateTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      now: dayjs(),
    };
  },
  computed: {
    rightNow() {
      return this.now;
    },
  },
  created() {
    // If this flag is set, automatically update `now` every second
    if (this.autoUpdateDateTime) {
      setInterval(this.updateNow, 1000);
    }
  },
  methods: {
    /**
     * This method is expecting time format from `vue2-timepicker`
     * @param time12h
     * @returns {{hh: *, mm: *, A: *}|boolean}
     */
    convert12To24(time12h) {
      if (typeof time12h !== 'object') {
        return false;
      }

      let { hh, mm, A } = time12h;

      if (hh === '12') {
        hh = '00';
      }

      if (A === 'PM') {
        hh = parseInt(hh, 10) + 12;
      }

      return {
        hh,
        mm,
        A,
      };
    },
    isAlmostEnding(dateTime) {
      const diff = this.verifyDayjs(dateTime).diff(dayjs(), 'minute');

      return diff <= 15;
    },
    isBetween(from, to) {
      // Make sure we have valid moment instances
      from = this.verifyDayjs(from);
      to = this.verifyDayjs(to);

      return dayjs().isBetween(from, to);
    },
    formatDate(dateTime, format) {
      format = format || 'MMMM D';
      dateTime = this.verifyDayjs(dateTime);

      return dateTime.isValid() ? dateTime.format(format) : 'n/a';
    },
    formatISODate(dateTime, ignoreUtc) {
      dateTime = this.verifyDayjs(dateTime);
      ignoreUtc = ignoreUtc || false;

      if (dateTime.isValid()) {
        return ignoreUtc ? dateTime.utcOffset(0, true).toISOString() : dateTime.toISOString();
      }

      return '';
    },
    getCurrentISODate() {
      return dayjs().toISOString();
    },
    fullDate(dateTime) {
      dateTime = this.verifyDayjs(dateTime);

      return dateTime.isValid() ? dateTime.format(fullDateFormat) : 'n/a';
    },
    fullDateTime(dateTime) {
      dateTime = this.verifyDayjs(dateTime);

      return dateTime.isValid() ? dateTime.format(fullDateTimeFormat) : 'n/a';
    },
    shortDateTime(dateTime) {
      dateTime = this.verifyDayjs(dateTime);

      return dateTime.isValid() ? dateTime.format(shortDateTimeFormat) : 'n/a';
    },
    tillNow(dateTime) {
      const diff = this.verifyDayjs(dateTime).diff(this.rightNow);
      const duration = dayjs.duration(diff);

      return {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
      };
    },
    timeAgo(dateTime) {
      if (!dateTime) {
        return 'unknown';
      }

      dateTime = this.verifyDayjs(dateTime);

      // If we can't figure out this date, punt it.
      if (!dateTime.isValid()) {
        return 'n/a';
      }

      // https://momentjs.com/docs/#/displaying/fromnow/
      return dayjs().diff(dateTime, 'hour') > 3
        ? dateTime.format(shortDateTimeFormat)
        : dateTime.fromNow();
    },
    timeToGo(dateTime) {
      if (!dateTime) {
        return 'unknown';
      }

      dateTime = this.verifyDayjs(dateTime);

      // https://momentjs.com/docs/#/displaying/tonow/
      return dateTime.isValid() ? dayjs().to(dateTime) : 'n/a';
    },
    updateNow() {
      this.now = dayjs();
    },
    verifyDayjs(maybeDayjs) {
      // Make sure we have a moment instance
      if (!dayjs.isDayjs(maybeDayjs)) {
        // As long as this is a standard date format, it should get picked up.
        maybeDayjs = dayjs(maybeDayjs);
      }

      return maybeDayjs;
    },
  },
};

// leave the export, even if you don't use it
export default hasTimeDate;
