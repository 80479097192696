<template>
  <tr>
    <td>
      <router-link
        :to="{ name: 'donation', params: { id: donation.donation_id } }"
        v-if="can('view', 'donations')"
      >
        {{ donation.donation_id }}
      </router-link>
      <template v-else>
        {{ donation.donation_id }}
      </template>
      <FavoriteComment :donation_id="donation.donation_id" :donor_id="donation.donor_id" />
    </td>
    <td class="no-wrap">
      {{ fullDateTime(donation.payment_made_at) }}
    </td>
    <td class="no-wrap">
      <router-link
        :to="{ name: 'donor', params: { id: donation.donor_id } }"
        v-if="can('view', 'donors')"
      >
        {{ display_as }}
      </router-link>
      <template v-else>
        {{ display_as }}
      </template>
    </td>
    <td>{{ donor.city }}</td>
    <td class="has-money">{{ formatAmountStat(donation.amount) }}</td>
    <td>
      <font-awesome-icon
        v-if="donation.recurring"
        icon="redo"
        class="text-warning"
        title="Recurring Donation"
      />
      <font-awesome-icon
        v-else
        icon="money-bill-alt"
        class="text-success"
        title="Single Donation"
      />
    </td>
    <td class="text-lg">{{ comments }}</td>
  </tr>
</template>

<script>
import hasTimeDate from 'mixins/hasTimeDate';
import hasStatistics from 'mixins/hasStatistics';
import FavoriteComment from 'components/favorite-comments/FavoriteComment';

export default {
  name: 'FavoriteCommentsListRow',
  components: { FavoriteComment },
  mixins: [hasStatistics, hasTimeDate],
  props: {
    favoriteComment: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    amount() {
      return this.donation.amount.toFixed(2);
    },
    comments() {
      return this.donation.comments ?? '-';
    },
    donation() {
      return this.favoriteComment.donation;
    },
    donor() {
      return this.favoriteComment.donor;
    },
    display_as() {
      return this.donation.anon ? 'Friend' : this.donor.first;
    },
  },
};
</script>

<style lang="scss"></style>
