<template>
  <div>
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="mt-4" />
    <template v-else>
      <div class="row my-2">
        <div :class="{ 'col-sm-10': downloadAllowed, 'col-sm-12': !downloadAllowed }">
          <PaginationResults
            title-suffix="comments"
            :total="meta.pagination.total"
            :current-page="pageNum"
            :total-pages="meta.pagination.total_pages"
            :per-page="perPage"
            @searchQuery="fetchData"
          />
        </div>
        <div class="col-sm-2 text-right" v-if="downloadAllowed">
          <a :href="downloadAllUrl" class="btn btn-outline-primary" download>
            <font-awesome-icon icon="download" /> Download All
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table
            :class="{
              'donations-list': true,
              table: true,
              'table-striped': striped && hasDonations,
              'table-hover': hover,
            }"
          >
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Received</th>
                <th scope="col">Name</th>
                <th scope="col">City</th>
                <th scope="col">Amount</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!hasDonations">
                <td colspan="6">There are no favorite comments.</td>
              </tr>
              <FavoriteCommentsListRow
                v-else
                v-for="donation in donations"
                :key="donation.donation_id"
                :favoriteComment="donation"
              />
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        :per-page-options="[20, 48, 96]"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>
  </div>
</template>

<script>
import { axiosInstance as axios, queryParams } from '../../plugins/axios';
import hasTimeDate from 'mixins/hasTimeDate';
import paginationFunctions from 'mixins/paginationFunctions';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PaginationResults from 'components/PaginationResults';
import FavoriteCommentsListRow from 'components/favorite-comments/FavoriteCommentsListRow';
import Pagination from 'components/Pagination';

export default {
  name: 'FavoriteCommentsList',
  mixins: [hasTimeDate, paginationFunctions],
  components: {
    Pagination,
    FavoriteCommentsListRow,
    Error,
    Loading,
    PaginationResults,
  },
  props: {
    hover: {
      type: Boolean,
      default: false,
      required: false,
    },
    striped: {
      type: Boolean,
      default: false,
      required: false,
    },
    downloadAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      donations: false,
      error: null,
      isLoading: true,
      meta: false,
      pageNum: 1,
      perPage: 96,
    };
  },
  computed: {
    hasDonations() {
      return this.donations.length > 0;
    },
    downloadAllUrl() {
      let url = 'favorite-comments/export';

      const payload = {
        saveToStore: false,
        params: {
          per_page: 0,
        },
      };

      const where = [`user_id,${this.userId}`];

      if (where.length) {
        payload.params.where = where.join('|');
      }

      return `${axios.defaults.baseURL}/${url}${queryParams(payload.params)}`;
    },
    userId() {
      return this.$store.getters['users/getActiveUser'].user_id;
    },
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData(params) {
      this.error = null;
      this.isLoading = true;

      const defaultParams = {
        page: this.pageNum,
        per_page: this.perPage,
        with: 'donation;donor',
      };

      const payload = {
        params: Object.assign(defaultParams, params || {}),
      };

      const where = [`user_id,${this.userId}`];

      if (where.length) {
        payload.params.where = where.join('|');
      }

      this.$store
        .dispatch('favoriteComments/getAll', payload)
        .then((response) => {
          // console.log('DonationsList fetchData() then', response);
          const { data, meta } = response.data;
          this.donations = data;
          this.meta = meta;

          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    /**
     * By default, we want all the donations ever given, so there is no date range set. If there is a Campaign or
     * Promotion passed as a prop, we want to make sure the returned Donations fall in the timeframe of the
     * Campaign/Promotion.
     *
     * A manually set date/time in the UI would take precedence over any dates set in the
     * Campaign/Promotion. The UI must ensure that those manual dates dont go outside the bounds of the
     * Campaign/Promotion if they were passed as props.
     *
     * @return {boolean}
     */
    getDateLimits() {
      // Default to no limits;
      let limits = false;
      let starts_at = false;
      let ends_at = false;

      if (this.dates.start && this.dates.end) {
        // Start with the most specific, manually set dates in the search bar. This takes precedence over all others
        // ({ starts_at, ends_at } = this.dates);
        starts_at = this.dates.start;
        ends_at = this.dates.end;
      }

      // See if there is a date range set in either Campaign or Promotion that was passed as a prop
      // Use these dates to narrow the scope of the selected dates
      if (this.campaign) {
        // If there is a campaign or promotion, start there
        if (starts_at) {
          // Pick the latter of the selected start date or the campaign start date
          starts_at = this.verifyDayjs(this.campaign.starts_at).isAfter(
            this.verifyDayjs(starts_at)
          );
        }
        ({ starts_at, ends_at } = this.campaign);
      }
      if (this.promotion) {
        // Start with the most specific, manually set dates
        ({ starts_at, ends_at } = this.promotion);
      }

      if (starts_at && ends_at) {
        // If we have values for the date range, set them and pass back to the caller
        limits = `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`;
      }

      return limits;
    },
  },
};
</script>

<style></style>
